<template>
  <div style="overflow: auto; padding-bottom: 40px">
    <!-- 搜索列 -->
    <el-form
      ref="queryParams"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleCurrentChange"
    >
      <el-form-item>
        <el-input
          v-model="queryParams.operator"
          placeholder="操作人"
          clearable
        />
      </el-form-item>
      <el-form-item class="minItem">
        <el-date-picker
          v-model="queryParams.dateTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          clearable
          v-model="queryParams.businessOptType"
          placeholder="操作类型"
        >
          <el-option
            v-for="(item, index) in OptTypeList"
            :key="index"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleCurrentChange"
          style="width: 80px"
          >查 询</el-button
        >
        <el-button @click="handleReset" style="width: 80px" plain
          >重 置</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table
      :data="operaList"
      v-loading="loading"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#F2F3F5' }"
    >
      <el-table-column prop="createTime" label="操作时间" align="center" />
      <el-table-column prop="operator" label="操作人" align="center" />
      <el-table-column prop="businessOptType" label="操作类型" align="center" />
      <el-table-column prop="content" label="操前后" align="center" />
      <el-table-column prop="remark" label="备注" align="center" />
    </el-table>
    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  businessOptType, //获取日志类型
  businessOptLog, //操作日志列表
} from "@/api/apiAll/phpUrl";
export default {
  name: "",
  data() {
    return {
      loading: false,
      total: 0,
      OptTypeList: [], //日志类型
      //列表搜索
      queryParams: {
        id: "",
        page: 1,
        perPage: 10,
        dateTime: [],
        startTime: "",
        endTime: "",
        operator: "", //操作人
        businessOptType: "", //操作类型
      },
      operaList: [], //日志列表
    };
  },
  created() {
    this.getOptType(); //获取日志类型
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  filters: {
    businessOptTypeFilter(v) {
      switch (v) {
        case 0:
          return "待对账";
        case 1:
          return "正常";
        case 2:
          return "冻结";
        case 3:
          return "已分账";
      }
    },
  },
  methods: {
    /** 获取日志类型 */
    async getOptType() {
      var res = await businessOptType();
      this.OptTypeList = res.data;
    },
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    async getList() {
      this.queryParams.id = this.$route.query.id;
      if (this.queryParams.dateTime && this.queryParams.dateTime.length > 0) {
        this.queryParams.startTime = this.queryParams.dateTime[0];
        this.queryParams.endTime = this.queryParams.dateTime[1];
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      this.loading = true;
      var res = await businessOptLog(this.queryParams);
      this.loading = false;
      if (res.code == 200) {
        this.operaList = res.data.data;
        this.total = res.data.total;
      }
      this.setquery();
    },
    //搜索
    handleCurrentChange() {
      this.queryParams.page = 1;
      this.getList();
    },
    //重置
    handleReset() {
      this.dateTime = [];
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped></style>
