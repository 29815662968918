<template>
  <div class="message-box scorr-roll">
    <!-- 文本信息 -->
    <div>
      <div class="posint-box">
        <div>
          <div>
            <span>选择分账规则</span>
            <el-select
              v-model="datatext.id"
              placeholder="请选择分账规则"
              :disabled="$route.query.type == 1 ? true : false"
              @change="changeselect"
            >
              <el-option
                v-for="(item, index) in ListOp"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="datatext.id == 12" class="datatext-day">
            当前分账规则中设置的分账时间为【{{
              datatext.day
            }}天】，代表订单状态为“交易结束”开始计时，{{
              datatext.day
            }}天后从平台分账至供应商账户
          </div>
          <div style="margin: 20px 0px; text-align: left; font-size: 14px">
            提现分账周期
          </div>
          <div style="margin: 0px 0px 20px 0px">
            <el-radio-group v-model="datatext.calcModel" @change="changeradio">
              <el-radio :label="1">每月固定日期</el-radio>
              <el-radio :label="2">滚动日期</el-radio>
            </el-radio-group>
          </div>
          <div v-if="datatext.calcModel == 1">
            <div>
              每月
              <el-input-number
                style="width: 150px"
                v-model="datatext.period"
                controls-position="right"
                :min="1"
                :max="31"
                :step="1"
                step-strictly
              />
              号提现到新网一次
            </div>
            <div class="mar10" style="color: #f77234; font-size: 14px">
              每月指定某天进行提现，若存在跨月的订单，系统将自动过滤次月订单提现。
            </div>
          </div>
          <div v-else>
            <div>
              每
              <el-input-number
                style="width: 150px"
                v-model="datatext.period"
                controls-position="right"
                :min="1"
                :max="100"
                :step="1"
                step-strictly
              />
              天提现到新网一次
            </div>
            <div class="mar10" style="color: #f77234; font-size: 14px">
              每次间隔配置天数进行提现，若存在跨月的订单，系统将自动过滤次月订单提现。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="operate-btn absbtn" v-if="!$route.query.type == 1">
      <div class="save1" @click="$router.push({ path: '/merchantCenterList' })">
        返回
      </div>
      <div class="save" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
import {
  withdrawCycle, //分账提现周期查询
} from "@/api/apiAll/phpUrl.js";
export default {
  data() {
    return {
      searchData: {},
      ListOp: [],
      datatext: {
        id: "",
        name: "",
        day: "",
        period: "",
        calcModel: "",
      },
      chain_ledger_mode: "", //连锁分账方式0:默认方式;1:线上分账
    };
  },
  computed: {
    ...merchantCenterList.mapState(["businessInfo"]),
  },
  async created() {
    this.chain_ledger_mode = this.businessInfo.chainLedgerMode;
    await this.getList();
    await this.getMesage();
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getCredentials",
      "getBusinessInfo",
      "getbusiness_ledgerlist",
      "getbusiness_ledgeredit",
      "businessEdit",
    ]),
    cole() {
      this.$router.push({ path: "/merchantCenterList" });
    },
    // 获取赛选
    async getList() {
      let data = await this.getbusiness_ledgerlist();
      this.ListOp = data.data;
      // this.datatext = this.ListOp.filter((item) => {
      //   return item.default == 1;
      // });
      // if (this.businessInfo.ledgerId != 0) {
      //   this.name = this.businessInfo.ledgerId;
      // } else {
      //   this.name = this.datatext[0].id;
      // }
      // this.datatext.length > 0
      //   ? (this.datatext = this.datatext[0])
      //   : (this.datatext = {});
    },
    // 获取详情
    async getMesage() {
      let data = await this.getbusiness_ledgeredit({
        id: this.businessInfo.ledgerId,
      });
      // this.name = data.data.id;
      Object.assign(this.datatext, data.data);

      let res = await withdrawCycle({
        id: this.$route.query.id,
      });
      if (res.code == 200) {
        this.datatext.period = res.data.period;
        this.datatext.calcModel = res.data.calcModel;
      }
    },
    handleChange(val) {
      const isNUm = val.row.num;
      if (!Number.isInteger(isNUm)) {
        this.$modal.msgError("件数必须是正整数");
        return;
      }
    },
    changeselect(e) {
      // this.datatext = this.ListOp.filter((item) => {
      //   return item.id == e;
      // });
      // this.datatext.length > 0
      //   ? (this.datatext = this.datatext[0])
      //   : (this.datatext = {});
      console.log(e);
      this.ListOp.forEach((item) => {
        if (item.id == e) {
          this.datatext.id = item.id;
          this.datatext.name = item.name;
          this.datatext.day = item.day;
        }
      });
    },
    changeradio() {
      if (this.datatext.calcModel == 1) {
        this.datatext.period = 8;
      } else {
        this.datatext.period = 2;
      }
    },
    //保存
    async save() {
      let data = await this.businessEdit({
        id: this.$route.query.id,
        ledger_id: this.datatext.id,
        chain_ledger_mode: this.chain_ledger_mode,
        period: this.datatext.period,
        calcModel: this.datatext.calcModel,
      });
      if (data.code == 200) {
        // this.getBusinessInfo({
        //   id: this.$route.query.id,
        // });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      await this.getCredentials({
        businessId: this.$route.query.id,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  // max-height: 75vh;
  // padding-top: 40px;
  padding-left: 20px;
  position: relative;

  .qualification-title {
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    color: #00bfbf;
    border-bottom: 1px solid #797979;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .qualification-message {
    margin-top: 10px;
    width: 100%;
    .title {
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4 px;
      background: #00bfbf;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .qualification-message-img-box {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .makeOut {
    padding-left: 5%;
    padding-right: 5%;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .makeout-box {
      margin-top: 10px;
      width: 40%;
      height: 40px;
      display: flex;
      .sch-1 {
        width: 80%;
        min-width: 200px;
        display: flex;
        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
    }
  }
}
::v-deep .el-descriptions-item__label {
  width: 45% !important;
  color: #666;
  margin-right: 20px;
  font-size: 14px;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
.posint-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 14px;
    margin-right: 20px;
  }
}
.datatext-day {
  // margin-left: 39%;
  color: #f77234;
  font-size: 14px;
  line-height: 24px;
  width: 331px;
  margin-top: 20px;
}
.datatext-huis {
  color: rgb(245, 154, 35);
  font-size: 14px;
}
</style>
