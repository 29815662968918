<template>
  <div class="pay-box">
    <div class="pay" v-for="(payList, keyName) in cashPaylist" :key="keyName">
      <div class="title">{{ keyName }}</div>
      <div class="fx" style="margin-bottom: 30px">
        <div v-for="(item, index) in payList" :key="index" class="fx">
          <div class="pay-change">
            <div class="pay-name">{{ item.payTypeName }}</div>
            <el-switch
              v-model="item.deactivate"
              active-color="#06b7ae"
              inactive-color="#d7d7d7"
              :active-value="0"
              :inactive-value="1"
              :disabled="$route.query.type == 1"
              @change="changsliitem(item, index)"
            >
            </el-switch>
          </div>
          <div class="goods-img" v-if="item.payTypeName == '线下扫码'">
            <UpimgList
              :imgnumber="1"
              :disabled="false"
              :imglist="item.pictureUrl"
              :imageSize="3"
              :nourl="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
import UpimgList from "@/components/Updata/UpimgList.vue";
export default {
  name: "",
  components: {
    UpimgList,
  },
  data() {
    return {
      cashPaylist: {},
    };
  },
  created() {
    this.GetbusinessGetPayTypeApi();
  },
  methods: {
    ...merchantCenterList.mapActions([
      "GetbusinessGetPayType",
      "postbusinessUpdatePayType",
    ]),
    async GetbusinessGetPayTypeApi() {
      let res = await this.GetbusinessGetPayType({
        supplierId: this.$route.query.id,
      });
      if (res.code == "000000") {
        this.cashPaylist = res.content;
        Object.keys(this.cashPaylist).forEach((keyName) => {
          const payList = this.cashPaylist[keyName];
          payList.forEach((value, index) => {
            if (value.payTypeName == "线下扫码") {
              if (value.pictureUrl) {
                value.pictureUrl = [value.pictureUrl];
              } else {
                value.pictureUrl = [];
              }
            }
          });
        });
      }
    },
    async changsliitem(value, index) {
      var params = JSON.parse(JSON.stringify(value));
      if (params.payTypeName == "线下扫码") {
        if (params.deactivate == 0 && params.pictureUrl.length == 0) {
          this.$message.error("请添加二维码");
          await this.GetbusinessGetPayTypeApi();
          return;
        } else {
          params.pictureUrl = params.pictureUrl[0];
        }
      }
      params.supplierId = this.$route.query.id;
      var res = await this.postbusinessUpdatePayType(params);
      await this.GetbusinessGetPayTypeApi();
      if (res.code == "000000") {
        this.$message.success("修改成功");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.up-box-bas {
  margin-left: 40px;
}
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  font-weight: bold;
  padding-bottom: 16px;
  margin-bottom: 10px;
  border-bottom: dashed 1px #ddd;
  display: flex;
  align-items: center;
}
.title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
.pay-box {
  overflow: auto;
  padding-bottom: 40px;
  .pay {
    .pay-change {
      margin-top: 25px;
      display: flex;
    }
    .goods-img {
      margin-left: 20px;
    }
  }
  .pay-name {
    text-align: right;
    width: 140px;
    margin-right: 30px;
  }
}
</style>
